import { FC, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography, styled } from "@mui/material";
import CustomButton from "../../Common/CustomButton";
import tublianLogo from "../../../images/bg-tublian.svg";
import CustomShareBtn from "../../Common/CustomShareBtn.tsx";
import { QueryType, RoadmapListItem } from "../Common/LearningTypes";
import { useLearnContext } from "./LearnContext";
import { userProfile } from "../../../libs/UserProfile";
import { closeSvg, primaryDownloadSvg } from "../../UserHome/Base/SVG";
import CertificatePreview from "./CourseCertificate/CertificatePreview";
import ProfileEndpoint from "../../../libs/ProfileEndpoint";

const RoadmapCard: FC<RoadmapCardProps> = ({ roadmap, queryType }) => {
  const history = useHistory();
  const certificateRef = useRef(null);
  const {
    setRoadmapPhasesList,
    setPopupStateData,
    handleDownloadCertificate,
    user,
  } = useLearnContext();
  const type = queryType || (roadmap.roadmap.queryType as QueryType);

  const userCache = userProfile(localStorage);
  // Find better method
  const stack = useMemo(() => {
    if (type !== "internship") return undefined;

    const learningPrompt = roadmap?.roadmap?.learningPrompt?.toLowerCase();

    if (learningPrompt.includes("javascript")) {
      return "Javascript";
    } else if (learningPrompt.includes("python")) {
      return "Python";
    }

    return undefined;
  }, [type, roadmap.roadmap.learningPrompt]);

  const handleDelete = (roadmap: RoadmapListItem) => {
    setPopupStateData({
      isActive: true,
      roadmap,
      feedbackData: {},
      popupMode: "delete",
    });
  };

  const handleClickRoadmap = async (roadmap: RoadmapListItem) => {
    const { roadmapId } = roadmap;
    if (!roadmapId) {
      console.error("Roadmap ID is undefined or null");
      return;
    }
    await ProfileEndpoint.updateRoadmapStatus(
      roadmap.userId,
      roadmap.roadmapId
    );

    setRoadmapPhasesList(roadmap);
    // console.log("🚀 ~ handleClickRoadmap ~ roadmap:", roadmap.roadmapId);
    history.push(`/learn/${userCache.userId()}/${roadmapId}`);
  };

  return (
    <>
      <StyledRoadmapCard type={type} key={roadmap.roadmapId}>
        <QueryTypeWrap>
          <Box width="100%" display="flex" justifyContent="space-between">
            <TagsWrap>
              <StyledQueryType type={type}>
                {type === "ai"
                  ? "AI Prompt"
                  : type === "internship"
                  ? "AI Internship"
                  : "User Prompt"}
              </StyledQueryType>

              {stack && <StyledQueryType>{stack}</StyledQueryType>}
            </TagsWrap>

            <StyledCardHeader>
              <Box className="closeBtn" onClick={() => handleDelete(roadmap)}>
                {closeSvg}
              </Box>
              <Box className="shareBtn">
                <CustomShareBtn
                  url={`https://www.tublian.com/learn/${roadmap.userId}/${roadmap.roadmapId}`}
                  title={roadmap.roadmap.learningPrompt}
                  description={`I found this learning roadmap and I thought you would like it`}
                />
              </Box>
            </StyledCardHeader>
          </Box>
          <StyledDescription type={roadmap?.roadmap?.roadmapType || "normal"}>
            {roadmap.roadmap.learningPrompt}
          </StyledDescription>
        </QueryTypeWrap>
        <Box sx={{ display: "flex", gap: "14px" }}>
          <CustomButton
            type="grey"
            size="medium"
            onClick={() => handleClickRoadmap(roadmap)}
          >
            View Roadmap
          </CustomButton>
          {roadmap.status && (
            <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
              <button
                style={{
                  background: "transparent",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 500,
                  color: "#FDD649",
                }}
                onClick={() =>
                  handleDownloadCertificate(roadmap.status, certificateRef)
                }
              >
                Download certificate
              </button>
              {primaryDownloadSvg}
            </Box>
          )}
        </Box>
      </StyledRoadmapCard>
      {roadmap.status && (
        <Box sx={{ opacity: 0, height: 0, overflow: "hidden" }}>
          <CertDownloadWrapper ref={certificateRef}>
            <CertificatePreview
              name={user}
              certificateId={roadmap.cert.id}
              date={roadmap.cert.date}
              text={roadmap.cert.text}
              certView="download"
            />
          </CertDownloadWrapper>
        </Box>
      )}
    </>
  );
};
export default RoadmapCard;

interface RoadmapCardProps {
  roadmap: RoadmapListItem;
  queryType?: QueryType;
}

const StyledRoadmapCard = styled(Box)<{ type: QueryType }>(({ type }) => ({
  borderRadius: "15px",
  background:
    type === "internship"
      ? "linear-gradient(#1E1E1E, #1E1E1E) padding-box, linear-gradient(90deg, #906BF5 0%, #0B81FA 100%) border-box"
      : "#1E1E1E",
  border: "1px solid transparent",
  padding: "16px 20px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "20px",
  "&:hover": {
    "& .shareBtn": {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    "& .closeBtn": {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
  },
}));

const QueryTypeWrap = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  width: "100%",
  gap: "8px",
});

const TagsWrap = styled(Box)({
  display: "flex",
  gap: "12px",
});

const StyledQueryType = styled(Typography)<{ type?: QueryType }>(
  ({ type }) => ({
    padding: "4px 6px",
    color: "#FEFEFE",
    fontSize: "0.75rem",
    fontWeight: 400,
    lineHeight: "1rem",
    background:
      type === "internship"
        ? "linear-gradient(#1E1E1E, #1E1E1E) padding-box, linear-gradient(90deg, #906BF5 0%, #0B81FA 100%) border-box"
        : "#292929",
    border: "1px solid transparent",
    borderRadius: "28px",
  })
);

const StyledCardHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: "#FEFEFE",
  gap: "20px",
  "& .shareBtn": {
    display: "none",
    width: "18px",
    height: "18px",
    "& svg": {
      color: "#FEFEFE",
    },
  },
  "& .closeBtn": {
    display: "none",
    width: "18px",
    height: "18px",
    "& svg": {
      width: "18px",
      height: "18px",
    },
  },
  [theme.breakpoints.down(700)]: {
    "& .shareBtn": {
      display: "flex",
      alignItems: "center",
    },
    "& .closeBtn": {
      display: "flex",
      alignItems: "center",
    },
    "& p": {
      color: "#FEFEFE",
      fontSize: "0.875rem",
      fontWeight: 700,
      lineHeight: "1.25rem",
      width: "65%",
    },
  },
}));

const StyledDescription = styled(Typography)<{ type: string }>(
  ({ theme, type }) => ({
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: "1.5rem",
    color: type === "godeeper" ? "#FEFEFE" : "#B7B7B7",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflowWrap: "break-word",
    overflow: "hidden",
  })
);

const CertDownloadWrapper = styled(Box)(({ theme }) => ({
  width: "891px",
  height: "630px",
  padding: "0px",
  overflow: "hidden",
  position: "relative",
  backgroundColor: "#ffffff",
  backgroundImage: `url(${tublianLogo})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
}));
