import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, Divider, Typography, styled } from "@mui/material";

import { useLearnContext } from "./LearnContext";
import CustomButton from "../../Common/CustomButton";
import TubliChatBot from "../../Common/TubliChatBot";
import MoreCourseDetails from "./MoreCourseDetails";
import CourseTasks from "./CourseTasks";
import { logEvent } from "../../../libs/amplitude";
import { userProfile } from "../../../libs/UserProfile";
import {
  CoursePresentationPageType,
  FilterOptions,
} from "../Common/LearningTypes";
import BannerCard from "../BannerCard";
import CustomizeCourseContent from "./CustomizeCourseContent";
import OverlayModal from "../../Common/OverlayModal";
import WarningPopup from "./WarningPopup";
import CourseFeedBack from "./CourseFeedback";
import { LearningContentSkeleton } from "../Skeletons/Skeletons";
import CustomToast from "../../Common/CustomToast";
import useCourseSectionSync from "./hooks/useCourseSectionSync";
import useFetchDeepdiveRoadmap from "./hooks/useFetchDeepdiveRoadmap";
import useFetchRoadmapData from "./hooks/useFetchRoadmapData";
import StatusNotification from "../../Common/StatusNotification";
import RenderDeepDiveSection from "./RenderDeepDiveSection";
import CourseLearningContent from "./CourseLearningContent";
import {
  ArrowBackOutlined,
  CheckCircleOutlineRounded,
  KeyboardArrowDown,
} from "@mui/icons-material";

const CoursePresentation = () => {
  const history = useHistory();

  let { roadmapId, courseId } = useParams<CoursePresentationPageType>();
  const [asideOpen, setAsideOpen] = useState(false);

  const {
    error,
    activeSectionId,
    activeCourse,
    learningProgress,
    loadingState,
    aiQuestions,
    popupStateData,
    deepdiveRoadmapPhases,
    recentInteractedRoadmaps,
    setError,
    setActiveSectionId,
    fetchAiQuestions,
    fetchLearningProgress,
    updateLoadingState,
    setPopupStateData,
    fetchInteractedRoadmapPhases,
  } = useLearnContext();

  const [filterOpen, setFilterOpen] = useState(false);
  const [alertActive, setAlertActive] = useState(false);

  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    contentType: "both",
    timeFrame: 15,
  });

  const updateFilterOptions = <T extends keyof FilterOptions>(
    option: T,
    newValue: FilterOptionValue<T>
  ) => {
    setFilterOptions((prev) => ({ ...prev, [option]: newValue }));
  };

  const handleFilter = (filterOptions: FilterOptions) => {
    setFilterOptions(filterOptions);
    setAlertActive(false);
    fetchLearningProgress(activeCourse, filterOptions, undefined, roadmapId);
  };

  const handleBack = () => {
    if (window.history.length > 1) {
      history.goBack();
    }
  };

  const handleCloseToast = () => {
    setPopupStateData((prev) => ({
      ...prev,
      toastData: {
        duration: 6000, // in Ïmiliseconds
        title: "",
        subtitle: "",
        actionMessage: "",
        action: undefined,
      },
      popupMode: "",
    }));
  };

  const shouldLoadMoreResources =
    learningProgress?.progress[activeSectionId] &&
    !loadingState.fetchingLearningContent &&
    !loadingState.fetchingMoreLearningContent &&
    !learningProgress.progress[activeSectionId].isContentPopulated;

  // Refactor: reduce number of useeffects
  useEffect(() => {
    if (learningProgress?.progress.contentFilters) {
      setFilterOptions(learningProgress?.progress.contentFilters);
    }
  }, [learningProgress]);

  useCourseSectionSync(activeSectionId, setActiveSectionId);

  useFetchRoadmapData(roadmapId, courseId);

  useEffect(() => {
    if (
      activeCourse &&
      (learningProgress?.roadmapId !== activeCourse.phaseRoadmapId ||
        learningProgress?.pathwayId !== activeCourse.id ||
        !learningProgress?.progress?.[activeSectionId]?.isContentPopulated)
    ) {
      fetchLearningProgress(activeCourse, undefined, undefined, roadmapId);
    }

    const hasAiQuestions =
      aiQuestions &&
      aiQuestions[activeCourse?.id] &&
      aiQuestions[activeCourse?.id][activeSectionId] &&
      aiQuestions[activeCourse?.id][activeSectionId]?.length > 0;

    if (!hasAiQuestions) {
      fetchAiQuestions(activeCourse);
    }

    logEvent("8020_Course", userProfile(localStorage).userId(), {
      courseName: activeCourse.courseName,
    });
    // eslint-disable-next-line
  }, [activeSectionId, activeCourse, roadmapId]);

  useEffect(() => {
    if (
      !loadingState.fetchingLearningContent &&
      recentInteractedRoadmaps?.[0]?.phase?.id !== activeCourse?.id
    ) {
      fetchInteractedRoadmapPhases(roadmapId);
    }
    // eslint-disable-next-line
  }, [
    loadingState.fetchingLearningContent,
    activeSectionId,
    activeCourse,
    roadmapId,
  ]);

  useFetchDeepdiveRoadmap(roadmapId);

  return (
    <>
      <CustomToast
        open={popupStateData.popupMode === "toast"}
        handleClose={handleCloseToast}
        duration={popupStateData.toastData?.duration}
        title={popupStateData.toastData?.title || ""}
        subtitle={popupStateData.toastData?.subtitle}
      />
      <OverlayModal
        isPreviewOpen={alertActive || popupStateData.isActive || error.error}
      >
        {error.error && (
          <StatusNotification
            status="error"
            statusMessage={error.errorMsg}
            cancelBtnName="Close"
            cancelBtnHandleClick={() =>
              setError({ error: false, errorMsg: "" })
            }
          />
        )}
        {alertActive && (
          <WarningPopup
            setAlertActive={setAlertActive}
            handleClick={() => handleFilter(filterOptions)}
          />
        )}
        {popupStateData.popupMode === "feedback" && <CourseFeedBack />}
      </OverlayModal>

      <SubHeaderWrap>
        <SubHeader>
          <TubliChatBot />
          <CustomButton size="small" type="tertiary" onClick={handleBack}>
            <ArrowBackOutlined style={{ marginRight: 8 }} />
            Back
          </CustomButton>

          <CustomizeCourseContent
            open={filterOpen}
            setOpen={setFilterOpen}
            filterOptions={filterOptions}
            updateFilterOptions={updateFilterOptions}
            setAlertActive={setAlertActive}
          />
        </SubHeader>

        {activeCourse?.phaseType === "godeeper" && (
          <GoDeeperTag marginTop="20px" marginBottom="-8px">
            Deepdive
          </GoDeeperTag>
        )}

        <Box className="sectionSidebar" onClick={() => setAsideOpen(true)}>
          <CustomButton type="tertiary" size="medium">
            Sections
          </CustomButton>
        </Box>
      </SubHeaderWrap>
      <HeaderWrap>
        <BannerCard
          type="course"
          beta={false}
          loading={!activeCourse || activeCourse.id === ""}
          imgUrl={activeCourse?.imageUrl}
          title={activeCourse?.courseName}
          paragraphs={[activeCourse?.description]}
        />
      </HeaderWrap>
      <Divider style={{ margin: "20px 0", backgroundColor: "#888" }} />
      <StyledContentWrap>
        <MainContentBox>
          <LearningContainer>
            <Typography component="h3">
              Section {activeSectionId}:{" "}
              {activeCourse?.sections.get(activeSectionId)?.title}
            </Typography>
            {learningProgress?.progress?.[activeSectionId]?.status && (
              <CustomButton
                size="small"
                preImg={<StyledCheck htmlColor="#1AFF8C" />}
                type="secondary"
              >
                Completed
              </CustomButton>
            )}
          </LearningContainer>
          <StyledDescription>
            {activeCourse?.sections.get(activeSectionId)?.description}
          </StyledDescription>

          {loadingState.fetchingLearningContent && (
            <Box margin="20px 0">
              <LearningContentSkeleton />
            </Box>
          )}

          {!loadingState.fetchingLearningContent &&
            learningProgress?.progress[activeSectionId]?.content && (
              <Box className="cardWrap">
                {learningProgress?.progress[
                  activeSectionId
                ]?.learningObjectives?.map((obj, index) => (
                  <CourseLearningContent
                    obj={obj}
                    key={index}
                    index={index}
                    roadmapId={roadmapId}
                  />
                ))}
              </Box>
            )}

          {!loadingState.fetchingLearningContent &&
            !loadingState.fetchingMoreLearningContent &&
            !learningProgress?.progress?.[activeSectionId] && (
              <MoreResourcesBtnWrap
                sx={{ justifyContent: "flex-start", marginTop: "12px" }}
              >
                <CustomButton
                  postImg={<KeyboardArrowDown />}
                  type="tertiary"
                  size="medium"
                  // onClick={() => window.location.reload()}
                  onClick={() =>
                    fetchLearningProgress(
                      activeCourse,
                      undefined,
                      (value: boolean) =>
                        updateLoadingState(
                          "fetchingMoreLearningContent",
                          value
                        ),
                      roadmapId
                    )
                  }
                >
                  Load learning resources
                </CustomButton>
              </MoreResourcesBtnWrap>
            )}

          {shouldLoadMoreResources && (
            <MoreResourcesBtnWrap>
              <CustomButton
                postImg={<KeyboardArrowDown />}
                type="tertiary"
                size="medium"
                onClick={() =>
                  fetchLearningProgress(
                    activeCourse,
                    undefined,
                    (value: boolean) =>
                      updateLoadingState("fetchingMoreLearningContent", value),
                    roadmapId
                  )
                }
              >
                Load more learning resources
              </CustomButton>
            </MoreResourcesBtnWrap>
          )}

          <RenderDeepDiveSection
            deepdivePhase={deepdiveRoadmapPhases}
            roadmapId={roadmapId}
          />

          {loadingState.fetchingMoreLearningContent && (
            <Box>
              <LearningContentSkeleton />
            </Box>
          )}

          {activeCourse?.phaseType !== "godeeper" && (
            <CourseTasks
              roadmapId={roadmapId}
              courseId={courseId}
              activeCourse={activeCourse}
              activeSectionId={activeSectionId}
            />
          )}
        </MainContentBox>

        <StyledAsideContent open={asideOpen}>
          <MoreCourseDetails
            objectives={
              activeCourse?.sections.get(activeSectionId)?.learningObjectives
            }
            sectionProgress={learningProgress?.progress?.[activeSectionId]}
            closeHandler={() => setAsideOpen(false)}
          />
        </StyledAsideContent>
      </StyledContentWrap>
    </>
  );
};

export default CoursePresentation;

type FilterOptionValue<T extends keyof FilterOptions> = FilterOptions[T];

const HeaderWrap = styled(Box)(({ theme }) => ({
  margin: "0 0 22px",
  display: "flex",
  flexDirection: "column",
  gap: 12,
  [theme.breakpoints.down(700)]: {
    margin: "auto",
    marginBottom: 20,
    gap: 20,
    width: "100%",
    alignItems: "center",
  },
}));

const MainContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "67%",
  "& .cardWrap": {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "left",
    gap: 20,
    margin: "16px 0 28px",
  },
  [theme.breakpoints.down(810)]: {
    width: "62%",
  },
  [theme.breakpoints.down(768)]: {
    width: "100%",
  },
}));

const MoreResourcesBtnWrap = styled(Box)({
  display: "flex",
  flex: 1,
  justifyContent: "center",
  marginBottom: "20px",
});

const SubHeaderWrap = styled(Box)(({ theme }) => ({
  marginBottom: 28,
  "& .sectionSidebar": {
    display: "none",
    [theme.breakpoints.down(768)]: {
      display: "flex",
    },
  },
  [theme.breakpoints.down(768)]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
}));

const SubHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  justifySelf: "flex-start",
  gap: "24px",
  width: "100%",
  "& button": {
    paddingLeft: "0px !important",
  },
  [theme.breakpoints.down(768)]: {
    alignItems: "flex-start",
    flexDirection: "column",
    gap: "16px",
  },
}));

const LearningContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "12px 16px",
  flexWrap: "wrap",
  marginBottom: 8,
  "& h3": {
    color: "#FEFEFE",
    fontSize: "1.25rem",
    fontWeight: 700,
    lineHeight: 2,
  },
});

const StyledCheck = styled(CheckCircleOutlineRounded)({
  width: 20,
  height: 20,
});

const StyledDescription = styled(Typography)(({ theme }) => ({
  color: "#FEFEFE",
  fontSize: "1rem",
  fontWeight: 400,
  lineHeight: "1.5rem",
  maxWidth: "80%",
  [theme.breakpoints.down(700)]: {
    maxWidth: "100%",
  },
}));

const StyledContentWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  gap: "12px",
  [theme.breakpoints.down(700)]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const StyledAsideContent = styled(Box)<{ open: boolean }>(
  ({ theme, open }) => ({
    position: "sticky",
    // top: 72,
    top: 24,
    width: 297,
    backgroundColor: "#1E1E1E",
    border: "1px solid #292929",
    borderRadius: 20,
    padding: 20,
    [theme.breakpoints.down(768)]: {
      display: open ? "block" : "none",
      zIndex: 99,
      position: "absolute",
      top: 0,
      right: 0,
      width: "calc(100% - 16px)",
      borderRadius: 0,
      height: "100%",
    },
  })
);

const GoDeeperTag = styled(Typography)({
  padding: "4px 8px",
  color: "#FEFEFE",
  fontSize: "0.75rem",
  fontWeight: 500,
  lineHeight: "1rem",
  borderRadius: "20px",
  border: "1px solid transparent",
  alignSelf: "flex-start",
  background:
    "linear-gradient(#1e1e1e, #1e1e1e) padding-box, linear-gradient(109.15deg, #fbda61 0%, rgba(255, 90, 205, 0.84) 86.08%) border-box",
  width: "71px",
});
