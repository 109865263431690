import { useState } from "react";
import { Box, Icon, TextareaAutosize, Typography, styled } from "@mui/material";
import {
  CheckCircleOutlined,
  Pause,
  PlayArrowRounded,
  StopRounded,
} from "@mui/icons-material";
import CustomButton from "../../../../../Common/CustomButton";
import {
  linearRefreshSvg,
  microphoneSvg,
  sendSvg,
} from "../../../../../UserHome/Base/SVG";

const QnABtns = ({
  loading,
  correctness,
  recordingStatus,
  updateRecordingStatus,
  audioState,
  fetchData,
  handleTextAnswer,
  question,
  handleReset,
  maxTime,
  showAnswer,
  questionNumber,
  answered,
}) => {
  const mode = recordingStatus.mode;
  const status = recordingStatus.status;

  const isIdle = status === "idle";
  const isActive = status === "active";
  const isRecording = status === "recording";
  const isPaused = status === "paused";
  const isStopped = status === "stopped";
  const isReplaying = status === "replaying";
  const isUploading = recordingStatus.uploading;
  const isSubmitted = status === "submitted";

  const [questionResponse, setQuestionResponse] = useState("");

  const handleInputChange = (event) => {
    const response = event.target.value;
    setQuestionResponse(response);
  };

  const handleClickSend = () => {
    if (questionResponse.length > 2) {
      handleTextAnswer(questionResponse, question);
    }
  };

  const handleKeyDown = (event) => {
    // if (event.key === "Enter") {
    //   handleClickSend();
    // }
  };

  const handleButtonClick = (newStatus, selector) => {
    updateRecordingStatus("status", newStatus);
    document.querySelector(`._${questionNumber} .${selector}`).click();
  };

  const disableCopyPaste = (e) => {
    e.preventDefault();
  };

  if (isIdle) {
    if (answered) {
      return (
        <AnsweredBtn sx={{ marginTop: "16px" }}>
          <CheckCircleOutlined /> Answered
        </AnsweredBtn>
      );
    }

    return (
      <Box
        sx={{
          "& button": {
            fontSize: "0.875rem !important",
            lineHeight: "1.25rem !important",
          },
        }}
      >
        <CustomButton
          // preImg={microphoneSvg}
          type="grey"
          size="medium"
          children={"Answer Question"}
          onClick={() => {
            updateRecordingStatus("status", "active"); // For text first
            // updateRecordingStatus("mode", "voice"); // To make voice default
            // handleButtonClick("recording", "_1dpop");
          }}
        />
      </Box>
    );
  }

  return (
    <>
      <QnABtnsWrap>
        {/* Generate Answer */}
        {!loading && showAnswer && (
          <CustomButton
            style={{ width: "170px" }}
            children={"Generate Answer"}
            type="primary"
            size="medium"
            disabled={!isActive && !isSubmitted}
            onClick={() => {
              handleReset();
              updateRecordingStatus("status", "active");
              fetchData(question);
            }}
          />
        )}

        {isActive && mode === "text" && !isUploading && (
          <TextAreaBox onContextMenu={(event) => event.preventDefault()}>
            <StyledTextArea
              variant="standard"
              placeholder="Type your answer here"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck={false}
              // disabled={disabled}
              value={questionResponse}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onCut={disableCopyPaste}
              onCopy={disableCopyPaste}
              onPaste={disableCopyPaste}
            />

            <InputIconsWrap>
              <Icon
                onClick={() => {
                  updateRecordingStatus("mode", "voice");
                  handleButtonClick("recording", "_1dpop");
                }}
                sx={{ color: "#FEFEFE" }}
              >
                {microphoneSvg}
              </Icon>
              <Icon
                onClick={handleClickSend}
                sx={{
                  color: questionResponse.length > 2 ? "#FEFEFE" : "#292929",
                  cursor: "pointer",
                }}
              >
                {sendSvg}
              </Icon>
            </InputIconsWrap>
          </TextAreaBox>
        )}

        {isSubmitted && !isUploading && (
          <CustomButton
            type="grey"
            size="medium"
            preImg={
              !correctness ? (
                linearRefreshSvg
              ) : (
                <CheckCircleOutlined htmlColor="#1AFF8C" />
              )
            }
            children={correctness ? "Answered" : "Try Again"}
            onClick={() => {
              if (!correctness) {
                if (mode === "voice") {
                  if (audioState.audioDetails.blob) {
                    handleReset();
                  }
                  updateRecordingStatus("mode", "voice");
                  handleButtonClick("recording", "_1dpop");
                }

                if (mode === "text") {
                  handleReset();
                  setQuestionResponse("");
                  updateRecordingStatus("status", "active");
                }
              }
            }}
          />
        )}

        {!loading && !isIdle && !isSubmitted && mode === "voice" && (
          <RecControlsWrap>
            <div>
              {/* Mic btn */}
              <span style={{ color: "#888", cursor: "pointer" }}>
                {microphoneSvg}
              </span>

              {/* Duration */}
              <span className="currentDuration">
                {isStopped || isReplaying // Show duration of audio note
                  ? `
                  ${String(audioState.audioDetails.duration.m).padStart(2, "0")}
                  :
                  ${String(audioState.audioDetails.duration.s).padStart(2, "0")}
                `
                  : ` 
                ${String(
                  //Show countdown in the format MM:SS
                  Math.floor((maxTime - recordingStatus.seconds) / 60)
                ).padStart(2, "0")}
                :
                ${String((maxTime - recordingStatus.seconds) % 60).padStart(
                  2,
                  "0"
                )}
                `}
              </span>
            </div>

            {/* Control Buttons */}
            <div>
              {/* if recording show pause and stop */}
              {isRecording && (
                <>
                  <Pause
                    htmlColor="#FEFEFE"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleButtonClick("paused", "_3nQu5")}
                  />
                  <StopRounded
                    htmlColor="#F26663"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleButtonClick("stopped", "_1bSom")}
                  />
                </>
              )}

              {/* If paused, show resume and stop */}
              {isPaused && (
                <>
                  <PlayArrowRounded
                    htmlColor="#FEFEFE"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleButtonClick("recording", "_3nQu5")}
                  />
                  <StopRounded
                    htmlColor="#F26663"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleButtonClick("stopped", "_1bSom")}
                  />
                </>
              )}

              {/* If stopped, show play button to listen to vn */}
              {isStopped && (
                <PlayArrowRounded
                  htmlColor="#FEFEFE"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    updateRecordingStatus("status", "replaying");
                    document.querySelector("div._1YOWG audio").play();
                  }}
                />
              )}

              {/* If replaying, show pause button to pause listening to vn */}
              {isReplaying && (
                <Pause
                  htmlColor="#FEFEFE"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    updateRecordingStatus("status", "stopped");
                    document.querySelector("div._1YOWG audio").pause();
                  }}
                />
              )}
            </div>

            {!loading && (isStopped || isReplaying) && !isUploading && (
              <SecondQnABtnsWrap>
                <CustomButton
                  children="Submit Answer"
                  type="grey"
                  size="medium"
                  onClick={() => handleButtonClick("submitted", "_37kfa")}
                />
              </SecondQnABtnsWrap>
            )}
          </RecControlsWrap>
        )}
      </QnABtnsWrap>
    </>
  );
};

export default QnABtns;

const AnsweredBtn = styled(Typography)({
  borderRadius: "20px",
  background: "#292929",
  padding: "8px 12px",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  color: "#FEFEFE",
  fontSize: "0.875rem",
  fontWeight: 500,
  lineHeight: "1.25rem",
  width: "119px",
  cursor: "not-allowed",
  "& svg": {
    width: "20px",
    height: "20px",
    color: "#1AFF8C",
  },
});

const QnABtnsWrap = styled(Box)(({ theme }) => ({
  "& div button": {
    padding: "8px 12px !important",
  },
  display: "flex",
  gap: 20,
  marginTop: 16,
  [theme.breakpoints.down(976)]: {
    "& button": {
      fontSize: "0.75rem !important",
    },
  },
  [theme.breakpoints.down(700)]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

const TextAreaBox = styled(Box)(({ theme }) => ({
  width: "calc(100% - 170px)",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down(700)]: {
    width: "100%",
  },
}));

const RecControlsWrap = styled(Box)({
  display: "flex",
  alignItems: "center",
  borderRadius: 10,
  paddingLeft: 12,
  gap: "8px",
  "& div": {
    display: "flex",
    alignItems: "center",
    gap: 8,
    "& .currentDuration": {
      color: "#888",
    },
  },
});

const SecondQnABtnsWrap = styled(Box)({
  marginLeft: "8px",
  "& div button": {
    padding: "8px 12px !important",
  },
});

const StyledTextArea = styled(TextareaAutosize)({
  width: "90%",
  background: "transparent",
  minHeight: "20px",
  maxHeight: "60px",
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  color: "#CFCFCF",
  ":focus": {
    padding: "8px 12px",
  },
  "::placeholder": {
    color: "#888",
  },
  ":focus::placeholder": {
    // Hide placeholder once active
    color: "transparent",
  },
  resize: "none",
});

const InputIconsWrap = styled(Box)({
  display: "flex",
  gap: "14px",
  alignItems: "flex-end",
  height: "100%",
  paddingBottom: "8px",
  "& svg": {
    width: "18px",
    height: "18px",
  },
});
