import { FC, useEffect, useState } from "react";
import {
  Box,
  Icon,
  Typography,
  styled,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import { useLearnContext } from "./LearnContext";
import { ILearningProgress, RoadmapListItem } from "../Common/LearningTypes";
import CustomButton from "../../Common/CustomButton";
import CoursePresentationCard from "./CoursePresentationCard";
import ProfileEndpoint from "../../../libs/ProfileEndpoint";
import CustomLoader from "../../Common/CustomLoader";
import { arrowRightSvg } from "../../UserHome/Base/SVG";

const RenderDeepDiveSection: FC<RenderDeepDiveSectionProps> = ({
  deepdivePhase,
  roadmapId,
}) => {
  const {
    activeSectionId,
    activeCourse,
    learningProgress,
    loadingState,
    createDeepdiveRoadmap,
    getPhaseProgress,
  } = useLearnContext();

  const [phaseProgress, setPhaseProgress] = useState<
    ILearningProgress | undefined
  >(undefined);

  const showGoDeeperBtn =
    !loadingState.creatingDeepdiveRoadmap &&
    !loadingState.fetchingLearningContent &&
    !loadingState.fetchingMoreLearningContent &&
    learningProgress?.progress?.[activeSectionId] &&
    !activeCourse?.sections.get(activeSectionId)?.goDeeperRoadmapId &&
    activeCourse.phaseType !== "godeeper";

  const showDeepdiveRoadmap =
    deepdivePhase &&
    deepdivePhase?.roadmap?.phases?.length > 0 &&
    !loadingState.creatingDeepdiveRoadmap &&
    activeCourse?.sections.get(activeSectionId)?.goDeeperRoadmapId &&
    activeCourse?.phaseType !== "godeeper";

  useEffect(() => {
    const fetchPhaseProgress = async () => {
      try {
        const phaseId = deepdivePhase?.roadmap?.phases?.[0]?.id;
        // console.log("🚀 ~ fetchPhaseProgress ~ phaseId:", phaseId);

        if (phaseId) {
          const response = await ProfileEndpoint.getPhaseStatus(phaseId);

          // console.log("🚀 ~ fetchAllPhaseProgress ~ responses:", response);
          if (response?.error) throw new Error(response.error);

          response?.result && setPhaseProgress(response?.result);
        }
      } catch (error) {
        console.error("Error fetching deepdive phase progress:", error);
      }
    };

    if (
      deepdivePhase &&
      !phaseProgress &&
      !loadingState.creatingRoadmaps &&
      loadingState.loadingDeepdiveRoadmap === "loaded"
    ) {
      fetchPhaseProgress();
    }
  }, [deepdivePhase, phaseProgress, loadingState]);

  if (loadingState.creatingDeepdiveRoadmap)
    return (
      <LoaderWrap>
        <CustomLoader primaryMsg="Creating Your Deep dive Roadmap..." />
      </LoaderWrap>
    );

  if (
    !(
      showGoDeeperBtn ||
      showDeepdiveRoadmap ||
      loadingState.creatingDeepdiveRoadmap
    )
  )
    return null;

  return (
    <Box marginBottom="20px" alignSelf="flex-start">
      {showGoDeeperBtn && (
        <>
          <Typography
            color="#FEFEFE"
            fontWeight={700}
            fontSize="1.125rem"
            lineHeight="1.875rem"
            marginBottom="16px"
          >
            Go Deeper
          </Typography>
          <StyledTooltip
            title={
              <Box>
                <Typography component="h4">Dive Deeper</Typography>
                <Typography>
                  Eager to uncover more? Click "Go Deeper" and let 8020 unlock
                  additional insights and learning content. Go next level.
                </Typography>
              </Box>
            }
            placement="top-start"
            arrow={true}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, 0],
                    },
                  },
                ],
              },
            }}
          >
            <div style={{ width: "auto" }}>
              <CustomButton
                onClick={() =>
                  createDeepdiveRoadmap(
                    activeCourse.learningPrompt || "",
                    roadmapId
                  )
                }
                type="tertiary"
                postImg={<RightIcon>{arrowRightSvg}</RightIcon>}
              >
                Go Deeper on{" "}
                "{activeCourse?.sections.get(activeSectionId)?.title}"
              </CustomButton>
            </div>
          </StyledTooltip>
        </>
      )}

      {showDeepdiveRoadmap && (
        <Box display="flex" flexDirection="column" gap="16px">
          <Typography
            color="#FEFEFE"
            fontWeight={700}
            fontSize="1.125rem"
            lineHeight="1.875rem"
          >
            Deep Dive
          </Typography>

          {deepdivePhase?.roadmap?.phases?.map(
            ({ id, phaseTitle, overview, tags, sections }) => (
              <CoursePresentationCard
                key={id}
                course={{
                  id: id,
                  learningPrompt: deepdivePhase.roadmap.learningPrompt,
                  phaseType: "godeeper",
                  phaseRoadmapId: deepdivePhase.roadmapId,
                  imageUrl: deepdivePhase.roadmap.image || undefined,
                  courseName: phaseTitle,
                  description: overview,
                  stacks: tags,
                  courseTopics: tags,
                  structure: sections.map((s) => s.title),
                  sections: new Map(sections.map((s, j) => [j + 1, s])),
                }}
                completedCnt={getPhaseProgress(phaseProgress).completed}
                sectionsCnt={getPhaseProgress(phaseProgress).total}
                btnText="Go to phase"
                imageUrl={deepdivePhase.roadmap.image}
              />
            )
          )}
        </Box>
      )}
    </Box>
  );
};

export default RenderDeepDiveSection;

interface RenderDeepDiveSectionProps {
  deepdivePhase: RoadmapListItem | undefined;
  roadmapId: string;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#7F5DDD",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#7F5DDD",
    borderRadius: "15px",
    padding: "16px",
    maxWidth: "421px",
    // height: "184px",
    // height: "148px",
    color: "#FEFEFE",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    "& h4": {
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "1.5rem",
      marginBottom: "12px",
    },
    "& span": {
      fontWeight: 700,
    },
  },
}));

const RightIcon = styled(Icon)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "18px",
  height: "18px",
  "& svg": {
    width: "18px",
    height: "18px",
  },
});

const LoaderWrap = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "12px 0",
});
