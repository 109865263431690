import { useEffect } from "react";
import { Box, Typography, styled } from "@mui/material";
import CustomButton from "../../Common/CustomButton";
import { useHistory } from "react-router-dom";
import { useLearnContext } from "./LearnContext";
import ProgressBar from "../../Common/progressBar";
import QuestionLearningTasks from "./QuestionLearningTasks";
import { logEvent } from "../../../libs/amplitude";
import { userProfile } from "../../../libs/UserProfile";

import { QuestionTasksSkeleton } from "../Skeletons/Skeletons";
import { Course } from "../Common/LearningTypes";

const CourseTasks = ({
  roadmapId,
  courseId,
  activeCourse,
  activeSectionId,
}: {
  roadmapId: string;
  courseId: string;
  activeCourse: Course;
  activeSectionId: number;
}) => {
  const history = useHistory();
  const userCache = userProfile(localStorage);

  const {
    // activeCourse,
    // activeSectionId,
    loadingState,
    learningProgress,
    setActiveSectionId,
    handleSearch,
  } = useLearnContext();

  const completedTasks =
    learningProgress?.progress[activeSectionId]?.completedTasks.length || 0;

  const handlePrev = () => {
    if (activeSectionId !== 1) {
      setActiveSectionId((prev) => prev - 1);
      history.replace(
        `${window.location.pathname}?section=${activeSectionId - 1}`
      );
      logEvent("8020", userCache.userId(), {
        action: "openSection",
        roadmapId: roadmapId,
        phaseId: courseId,
        sectionId: activeSectionId - 1,
      });
    }
  };

  const handleNext = () => {
    if (activeSectionId !== 4) {
      setActiveSectionId((prev) => prev + 1);
      history.replace(
        `${window.location.pathname}?section=${activeSectionId + 1}`
      );
      logEvent("8020", userCache.userId(), {
        action: "openSection",
        roadmapId: roadmapId,
        phaseId: activeCourse.id,
        sectionId: activeSectionId - 1,
      });
    }
  };

  // const hasIssues =
  //   loadingState.searchStatus === "searched" &&
  //   (!searchResults || searchResults.length === 0);

  const isLoadingQuestions = loadingState.loadingAiQuestions !== "loaded";

  useEffect(() => {
    // should not search on render
    // only search if we have searched before and course or activeSectionId changes
    if (loadingState.searchStatus === "searched") {
      handleSearch(
        activeCourse?.sections.get(activeSectionId)?.category || "code changes", // Category
        false, // Not public
        activeCourse?.courseTopics // Course Topics
      );
    }
    // eslint-disable-next-line
  }, [activeCourse, activeSectionId]);

  return (
    <Box borderTop="1px solid #1E1E1E" paddingTop="20px">
      <Box sx={{ display: "flex", gap: "14px" }}>
        <SubHeader>Test your Knowledge</SubHeader>

        {!loadingState.fetchingLearningContent && completedTasks > 0 && (
          <ProgressWrap>
            <ProgressBar
              max={2}
              current={completedTasks}
              startText={`${completedTasks} of ${2} Tasks Completed`}
              // progressColor="#FEFEFE"
              startTextColor="#FEFEFE"
              height={2}
              borderRadius={2}
              margin={2}
              progressColor="linear-gradient(
                to right,
                #fbda61 0%,
                rgba(255, 90, 205, 0.84) 100%
              )"
              textRowStyles={{
                color: "#FEFEFE",
                "& p": { fontSize: "0.75rem", lineHeight: "1rem" },
              }}
            />
          </ProgressWrap>
        )}
      </Box>

      <SubHeaderDesc>
        Answer at least two questions to complete this section.
      </SubHeaderDesc>

      <AimText>{activeCourse?.sections.get(activeSectionId)?.aim}</AimText>

      {isLoadingQuestions ? (
        <QuestionTasksSkeleton />
      ) : (
        <QuestionLearningTasks roadmapId={roadmapId} />
      )}

      {/* <IssuesWrap>
        <StyledDivider />
        <CustomButton
          onClick={handleIssueDropdown}
          type="tertiary"
          postImg={
            <StyledIcon>{issuesOpen ? arrowUpSvg : arrowDownSvg}</StyledIcon>
          }
          style={{
            alignSelf: "flex-start",
          }}
        >
          Seeking real-world experience? Try these Open Source projects!
        </CustomButton>

        {issuesOpen && (
          <>
            <SubHeader>
              Open-source projects
              <span className="subHeaderInfo">
                {infoSvg}
                Note: this is optional
              </span>
            </SubHeader>

            {loadingState.searchStatus === "searched" &&
              (!searchResults || searchResults.length === 0) && (
                <Box display="flex" flexDirection="column">
                  <Typography
                    // color="#FEFEFE"
                    color="#B7B7B7"
                    fontSize="0.875rem"
                    lineHeight="1.25rem"
                    fontWeight="400"
                  >
                    No open-source projects found for this section.
                  </Typography>
                </Box>
              )}

            {(loadingState.searchStatus !== "searched" ||
              (searchResults && searchResults.length > 0)) && (
                <Box display="flex" flexDirection="column" gap="14px">
                  <IssuesLearningTasks />
                </Box>
              )}
          </>
        )}
      </IssuesWrap> */}

      <NavWrap>
        {activeSectionId !== 1 && (
          <Box
            sx={{
              "&>button": {
                "&:hover": {
                  background: "#292929 !important",
                },
              },
            }}
          >
            <CustomButton
              type="grey"
              size="medium"
              disabled={activeSectionId === 1}
              onClick={handlePrev}
              style={{
                background: "transparent",
                border: "1px solid #414141",
              }}
            >
              Previous section
            </CustomButton>
          </Box>
        )}

        {activeSectionId !== activeCourse.sections.size && (
          <CustomButton
            type="grey"
            disabled={activeSectionId === activeCourse.sections.size}
            size="medium"
            onClick={handleNext}
          >
            Next Section
          </CustomButton>
        )}
      </NavWrap>
    </Box>
  );
};

export default CourseTasks;

const SubHeader = styled(Typography)({
  display: "flex",
  alignItems: "center",
  color: "#FEFEFE",
  fontSize: "1.125rem",
  fontWeight: 700,
  lineHeight: "1.875rem",
  "& .subHeaderInfo": {
    display: "flex",
    alignItems: "center",
    marginLeft: "12px",
    gap: "6px",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    fontWeight: 400,
    "& svg": {
      color: "#FDD649",
      width: 18,
      height: 18,
    },
  },
});

const SubHeaderDesc = styled(Typography)({
  display: "flex",
  alignItems: "center",
  color: "#B7B7B7",
  fontSize: "0.875rem",
  fontWeight: 500,
  lineHeight: "1.25rem",
  margin: "8px 0",
});

const ProgressWrap = styled(Box)({
  background: "#1E1E1E",
  border: "1px solid #292929",
  borderRadius: "5px",
  width: "174px",
  padding: "6px 12px",
});

const NavWrap = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  gap: 20,
  margin: "26px 0 0",
  width: "100%",
});

const AimText = styled(Typography)({
  color: "#FEFEFE",
  fontSize: "1rem",
  fontWeight: 500,
  lineHeight: 1.25,
  marginBottom: 16,
});
